

.top {
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  color: rgba(0, 0, 0, .65);
  display: flex;
  font-size: 18px;
  justify-content: space-between;
  height: 64px;
  line-height: 1;
  padding: 0 10px;
  white-space: nowrap;
}

.top-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: inherit;
  font-weight: 400;
  flex: 1;
}

.top-userImg {
  margin: 0 8px 0 10px;
  padding: 2px;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  box-sizing: border-box;
  border: 1px solid #eee;
}

.top-button {
  display: flex;
  align-items: center;
}

.top-item {
  margin-right: 8px;
}
