$transparent: transparent;

$white: #ffffff;
$white10: rgba(255, 255, 255, 0.1);
$white15: rgba(255, 255, 255, 0.15);
$white20: rgba(255, 255, 255, 0.2);
$white30: rgba(255, 255, 255, 0.3);
$white50: rgba(255, 255, 255, 0.5);
$white80: rgba(255, 255, 255, 0.8);

$black: #000000;
$black5: rgba(0, 0, 0, 0.05);
$black10: rgba(0, 0, 0, 0.1);
$black20: rgba(0, 0, 0, 0.2);
$black30: rgba(0, 0, 0, 0.3);
$black50: rgba(0, 0, 0, 0.5);

$white: #ffffff;

$LightBlue: #a7b7ec;
$LightBlue2: #78aee5;
$btnBlue: #008AFF;
$disableBlue: #687592;
$disableBlueText: #061131;
$narrowBlue: #3385FF;

$cheng: #FF6B07;
$c333: #333333;
$c888: #888888;
$c88850: rgba(136, 136, 136, 0.5);
$c999: #999999;
$c666: #666666;
$aaa: #aaaaaa;
$ccc: #cccccc;
$bbb: #bbbbbb;
$bbb50: rgba(187, 187, 187, 0.5);
$ddd: #dddddd;
$eee: #eeeeee;

.dark-theme {

  //loading
  .el-loading-mask{
    background:$white20 url('../../assets/img/loading.svg') no-repeat;
    background-position: center center;
    background-size:100px 100px;
  }
  .el-loading-spinner{
    display: none;
  }
  background: url('dark.jpg') no-repeat 0 0;
  background-size: 100% 100%;
  color: $white;
  .top {
    border-bottom: 1px solid $white20;
    color: $white;
    background: $white10;
  }
  .main, .index, .index .main .router {
    background: $transparent !important;
  }
  .top .search {
    width: 258px;
    margin-left: -130px;
    color: $white;
  }
  .top .search input {
    border: 1px solid $white50;
    border-radius: 12px;
    background-color: $transparent !important;
  }
  .el-dropdown-selfdefine {
    color: $white;
  }
  .pifu {
    color: $white;
    border: 1px solid $white;
  }
  .index .left, .index .main {
    overflow-y: auto !important;
  }
  .left:before {
    background-color: $white30;
  }
  .left.is-active:before {
    background-color: $white30;
    width: 1px;
  }
  .el-menu--collapse {
    width: 72px;
  }
  //scroll bar
  ::-webkit-scrollbar-thumb {
    background: rgba(105, 125, 195, 0.6) !important;
  }
  ::-webkit-scrollbar-track-piece {
    background: rgba(77, 93, 149, 0.6) !important;
  }
  ::-webkit-scrollbar {
    background: $transparent !important;
  }
  //sidebar style
  .sidebar-container {
    background: rgba(204, 204, 204, 0.1);
  }
  .is-opened .el-submenu__title{
    background: rgba(0, 0, 0, 0.2) !important;
  }
  .el-menu {
    background: $transparent;
    .menu-wrapper .el-menu-item{
      background: $transparent!important;
    }
    .el-submenu.is-active {
      background: rgba(204, 204, 204, 0.1);
    }
    .el-menu-item {
      background: rgba(28, 44, 82, 0.4) !important;
    }
    .el-submenu__title:hover {
      background: $black10;
    }
    .el-menu-item:hover {
      background: rgba(28, 44, 82, 0.1) !important;
    }
    .el-menu-item.is-active {
      color: $white !important;
      background: rgba(0, 0, 0, 0.2) !important;
    }
  }
  .el-menu--popup-right-start{
    margin: 0;
  }
  .el-menu--vertical {
    background: rgba(28, 44, 82, 0.7) !important;
    .el-menu-item.is-active {
      background: rgba(0, 0, 0, 0.2) !important;
    }
  }
  .bg {
    background: rgba(255, 255, 255, 0.15);
    border-radius: 5px;
    margin-bottom: 15px;
  }
  .home {
    color: $white;
  }
  .shortcut:hover {
    background: rgba(255, 255, 255, 0.3);
  }
  .shortcut span {
    color: rgba(255, 255, 255, 0.8);
  }
  .monitorDetail .mDBottom {
    color: $white50;
  }
  .stageDetail .stageList {
    background: rgba(255, 255, 255, 0.34);
  }
  .stagePass {
    background: $white
  }
  .chart {
    color: $white;
  }
  .zi {
    background: rgba(170, 137, 189, 0.6);
  }
  .lan {
    background: rgba(0, 180, 255, 0.2);
  }
  .handleTitle-center .el-button {
    background: $transparent;
    color: $white;
    width: 60px;
    height: 26px;
    line-height: 26px;
    padding: 0;
    font-size: 12px;
    border: 1px solid $white50;
  }
  .handleTitle-center .el-button:focus, .handleTitle-center .el-button:hover, .handleTitle-center .el-button.active {
    background: $white30;
    color: $white;
  }
  .handleTitle-center .el-button.active:before {
    border-color: $white50;
  }
  .el-submenu__title {
    color: white;
  }
  .blackBox, #main > div:not(.home) {
    background: $white15;
    border-radius: 5px;
    padding: 20px;
  }
  #main > div.outer-layer {
    background: $transparent;
    padding: 0;
    .el-tab-pane, .secondPart {
      background: $white10;
      box-shadow: 0px 1px 5px 0px rgba(203, 201, 201, 0.5);
      border-radius: 4px;
      padding: 10px;
    }
  }
  .el-tabs .el-tabs__item.is-active {
    font-size: 16px;
  }
  .el-tabs .el-tabs__item {
    color: #ffffff !important;
  }
  .el-dialog {
    .el-textarea__inner{
      color:$black;
    }
    .vxe-table {
      &.scrollBg .vxe-table--fixed-left-wrapper {
        background: $white;
      }
      .vxe-table--fixed-right-wrapper {
        background: $white;
      }

    }
    .paginationContain {
      margin-top: 10px;
      border: 1px solid $transparent;
      .el-pagination {
        color:#606266!important;
      }
      .el-select .el-input .el-input__inner {
        background: $transparent;
        border: 1px solid $bbb;
        color:#606266!important;
      }
      .el-pagination .btn-next, .el-pagination .btn-prev {
        border: 1px solid $bbb;
        color: $c999;
        background: $transparent;
      }
      .el-pagination button:disabled {
        border: 1px solid $eee;
        color: $eee;
      }
      .el-pager li{
        color:#606266!important;
        margin-right: 10px;
        border-radius: 3px;
        font-size: 12px;
        min-width: 30px;
        height: 24px;
        line-height: 24px;
      }
      .el-pager li.active{
        background: $eee;
      }
    }
  }
  //vxe-table
  .vxe-table {
    background: $transparent;
    border-left: 1px solid $transparent;
    border-right: 1px solid $transparent;
    &:after, &:before {
      background: $transparent;
      border: none;
    }
    .vxe-header--column, .vxe-body--column, .vxe-footer--column {
      //background-image: none!important;
    }
    .vxe-table--footer {
      border-top: 1px solid $eee;
    }
    .vxe-checkbox.is--indeterminate > input:not(:checked) + .vxe-checkbox--icon:before {
      left: 0;
    }
    .vxe-table--fixed-left-wrapper {
      background: $transparent;
      border: none;
    }
    // .vxe-table--fixed-right-wrapper{
    //   background: $transparent;
    //}
    &.scrollBg .vxe-table--fixed-left-wrapper {
      background: url('dark2.jpg') no-repeat left bottom;
    }
    .vxe-table--fixed-right-wrapper {
      background: url('dark2.jpg') no-repeat right top;
    }
    .vxe-table--fixed-left-wrapper:before, .vxe-table--fixed-right-wrapper:before {
      border-top: 1px solid $transparent;
    }
    .vxe-table--header-wrapper .vxe-table--repair {
      border-bottom: $transparent;
    }
    .vxe-table--footer-wrapper, .vxe-table--header-wrapper {
      //border-top: 1px solid $transparent;
    }
    .vxe-table--header-wrapper {
      background: $transparent;
    }
    .vxe-body--row {
      background: $transparent;
    }
    &.size--medium .vxe-body--column, &.size--medium .vxe-footer--column, &.size--medium .vxe-header--column {
      border-right: none;
      border-bottom: 1px solid $transparent !important;
      //background-image: none;
    }
    .vxe-table--border-line {
      &:before, &:after {
        border: none;
      }
    }
    .vxe-cell {
      color: $white;
    }
    th .vxe-cell {
      color: $aaa;
    }
    .vxe-table--footer-wrapper {
      background: $transparent;
    }
    .vxe-table--main-wrapper>div:nth-last-child(2){
      scrollbar-color: transparent transparent;
      scrollbar-track-color: transparent;
      -ms-scrollbar-track-color: transparent;
      &::-webkit-scrollbar-thumb {
        background:$transparent !important;
      }
      &::-webkit-scrollbar-track-piece {
        background:$transparent !important;
      }
      &::-webkit-scrollbar {
        background: $transparent !important;
      }
    }
    .vxe-checkbox > input + .vxe-checkbox--icon {
      background: $transparent;
    }
    .el-button {
      font-size: 12px;
      font-weight: 400;
      &:hover span {
        border-bottom: 1px solid $white50;
        padding-bottom: 3px;
      }
      padding-right: 5px;
      padding-left: 5px;
      border: none;
      background: none;
      .el-icon--right {
        margin-left: 0px;
        margin-right: 2px;
      }
    }
    .disabled_button {
      color: $white50;
      cursor: no-drop;
    }
    .vxe-cell .vxe-input, .vxe-cell .vxe-default-select {
      height: 24px;
    }
  }
  .paginationContain {
    margin-top: 10px;
    border: 1px solid $transparent;
    .el-pagination {
      color: $white;
    }
    .el-pagination__total, .el-select .el-input .el-input__inner {
      color: $white;
    }
    .el-select .el-input .el-input__inner {
      background: $transparent;
      border: 1px solid $white;
      color: $white;
    }
    .el-pagination .btn-next, .el-pagination .btn-prev {
      border: 1px solid $white;
      color: $white;
      background: $transparent;
    }
    .el-pagination button:disabled {
      color: $white50;
      border-color: $white50;
    }
  }
  .el-pager li {
    border: 1px solid $white;
  }
  .el-pager li.active + li {
    border: 1px solid $white;
  }
  .el-pager li.active {
    color: $white;
    background: $white50;
  }
  div .el-button {
    background: $white20;
    color: $white;
    border: none;
    padding-right: 11px;
    padding-left: 11px;
  }
  div .el-button:hover {
    background: $white50;
  }
  div .el-button:active {
    background: $white80;
  }
  div.ciic-table-operate .el-button:hover,div.ciic-table-operate .el-button:hover{
    background: none;
  }
  //.el-tabs .el-tab-pane .divButtonRigth .el-button:hover {
  //  background: $white50;
  //}
  .el-input__inner {
    border: 1px solid $white;
    background: $transparent;
  }
  .el-input .el-input__inner {
    color: $white;
  }
  .el-message-box .el-button, .el-dialog .el-button {
    min-width: 60px;
    height: 30px;
    border-radius: 4px;
    background-color: $white50;
    border: 1px solid $LightBlue2;
    color: $LightBlue2;
    padding-top: 0;
    padding-bottom: 0;
  }
  .el-message-box .el-input__inner, .el-dialog .el-input__inner {
    border: 1px solid $bbb;
    background: $transparent;
    color: #000;
  }
  .el-message-box .is-disabled .el-input__inner, .el-dialog .is-disabled .el-input__inner {
    border: 1px solid #E4E7ED;
    background: #F5F7FA;
    color: #C0C4CC;
  }
  .el-message-box .el-button.el-button--primary {
    border: none;
    background-color: $LightBlue2;
    color: $white;
  }
  .el-tabs {
    .el-tabs__item {
      color: #ffffff;
    }
    .is-active {
      font-size: 18px;
      color: $cheng;
    }
    .el-tabs__item::after {
      background: $white;
    }
  }
  .customerBox {
    .customeInfo-pageGroup .el-button {
      background-color: $white;
      color: #2b3451;
    }
    .fixed-top-bg, .fixed-top, .fixed-top2 .el-tabs--top {
      background: -moz-linear-gradient(left, #2d4957, #2b3451);
      background: -o-linear-gradient(left, #2d4957, #2b3451);
      background: -webkit-linear-gradient(left, #2d4957, #2b3451);
    }
    .customerInfo-body-detail, .customerInfo-body-detail span + span {
      color: $white;
    }
    .customerInfo-name span {
      color: $white;
    }
    .customer-info-item {
      background: $white20;
      color: $white;
    }
    .el-form-item__label {
      color: $c999;
    }
    .el-form-item__content p {
      color: $white;
    }
    .customerInfo-btns .el-button {
      color: $white;
      border: 1px solid $white;
    }
    .customerInfo-btns .el-button.is-disabled {
      color: $aaa;
      border: 1px solid $aaa;
    }
    .el-button.is-disabled, .el-button.is-disabled:focus, .el-button.is-disabled:hover {
      background: none;
    }
    .el-input.is-disabled .el-input__inner {
      background: $disableBlue;
      color: $disableBlueText;
    }
    .el-dialog .el-input.is-disabled .el-input__inner {
      background-color: #f5f7fa;
      border-color: #e4e7ed;
      color: #c0c4cc;
      cursor: not-allowed;
    }
  }
  #main .customerBox.salary{
    background: $white20;
    padding-top: 10px;
  }
  .handleBtn {
    color: $white;
  }
  .customerBox .el-tabs__nav-scroll {
    border: none;
    background: transparent;
    // background:url('../../assets/img/tabBg.png') repeat-x;
    background-position: left bottom;
  }
  .customerBox .el-tabs__item {
    background: rgba(38, 47, 81, 1);
  }
  #main > div.customerBox {
    padding: 0;
    background: none;
  }
  .customerBox .customerInfo, .el-tabs__content {
    background: rgba(255, 255, 255, 0.1);
  }
  .editForm .el-form-item__label,
  .ruleForm .el-form-item__label{
    font-size: 13px;
    font-weight: 600;
    color: $c999;
  }
  .editForm .formText {
    font-size: 13px;
    font-weight: 400;
    color: rgba(226, 227, 227, 1);
  }
  .customeInfo-pageGroup {
    background: rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 1);
  }
  .customerBox .fixed-top-bg {
    height: 70px;
  }
  .el-link.el-link--primary {
    color: #fff;
  }
  .el-tabs .is-active {
    color: #FF6B07 !important;
  }
  //.itemLists {
  //  .itemTitle {
  //    background: $white20;
  //    p:first-child {
  //      color: $white;
  //    }
  //  }
  //  .itemListBox {
  //    background: $white10;
  //    p {
  //      color: #e2e3e3;
  //    }
  //  }
  //
  //}
  .fallbackClass {
    background: $black20;
  }

  .rendanFormula {
    background: $white10 !important;
    .formulaDetail {
      color: #ffffff;
    }
  }
  .batchBox {
    .batchStep p {
      color: $ddd;
    }
    .active.batchStep p {
      color: $white;
    }
    .batchSteps {
      background: $white10 !important;
    }
    .stepSplit {
      color: $white50;
    }
    .el-upload-dragger {
      background: $white10 !important;
      .el-icon-upload {
        color: $ddd;
      }
    }
    .batchStep p {
      color: $white;
    }
  }
  .matchBox {
    .showAll .el-checkbox__label {
      color: $white;
    }
  }
  .el-table {
    background: $transparent;
    color: #ffffff;
  }
  .el-table::before {
    height: 0;
  }
  .el-table th {
    background: $white20;
  }
  .el-table tr {
    background: $transparent;
  }
  .matchList {
    border-top: none;
  }
  .matchTable .el-table--enable-row-hover .el-table__body tr:hover > td {
    background: rgba(105, 125, 195, 0.4);
  }
  .el-table td, .el-table th.is-leaf {
    color: $white;
    border-bottom: 1px solid rgba(105, 125, 195, 0.4);
  }
  .checkOK {
    background-color: rgba(240, 249, 235, 0.8) !important;
  }
  .checkFail {
    background-color: rgba(254, 240, 240, 0.8) !important;
  }
  .failTip .content, .failError .content {
    color: $white;
  }
  //表格空数据
  .icon-kong {
    margin-top: 15px;
    font-size: 100px;
    color: $white30;
  }
  .noData{
    color:$white30;
  }
  //.vxe-table{
  //  .icon-kong{
  //    margin-top: 15px;
  //    font-size: 100px;
  //    color:#dbdbdb;
  //  }
  //  .noData{
  //    color:#dbdbdb;
  //  }
  //}
    .el-textarea__inner{
      background: $transparent;
      color:$white;
    }
  .el-input__count{
    background: $transparent;
  }
  .salaryItemHeader{
    background: $white10!important;
    color:$white;
  }
  .salaryTitle{
    color:$white!important;
  }
  .fixBtn{
    background: #081236!important;
  }
  .is--disabled.vxe-custom--option .vxe-checkbox--icon:before, .is--disabled.vxe-export--panel-column-option .vxe-checkbox--icon:before, .is--disabled.vxe-table--filter-option .vxe-checkbox--icon:before, .vxe-table .is--disabled.vxe-cell--checkbox .vxe-checkbox--icon:before {
    border-color: #5e6782;
    background-color: #5e6782;
  }
}

.light-theme {
  //loading
  .el-loading-mask{
    background:rgba(255,255,255,.9) url('../../assets/img/loading.svg') no-repeat;
    background-position: center center;
    background-size:100px 100px;
  }
  .el-loading-spinner{
    display: none;
  }
  background: rgba(246, 246, 245, 1);
  color: $black;

  .top {
    border-bottom: 1px solid $ccc;
    color: $c999;
    background-color: $white !important;
  }
  .main, .index, .index .main .router {
    background: $transparent !important;
  }
  .top .search {
    width: 258px;
    margin-left: -130px;
    color: #000000;
  }

  .top .search input {
    box-shadow: 0px 1px 7px 0px $black10;
    border-radius: 12px;
    background-color: $white !important;
  }
  .el-dropdown-selfdefine {
    color: #000000;
  }
  .pifu {
    color: $c999;
    border: 1px solid $c999;
  }
  .index .left, .index .main {
    overflow-y: auto !important;
  }
  .left:before {
    background-color: $transparent;
  }
  .el-menu--collapse {
    width: 72px;
  }
  //scroll bar
  ::-webkit-scrollbar-thumb {
    background: rgba(204, 204, 204, 0.5) !important;
  }
  ::-webkit-scrollbar-track-piece {
    background: rgba(204, 204, 204, 0.2) !important;
  }
  ::-webkit-scrollbar {
    background: $transparent !important;
  }


  //sidebar style
  .sidebar-container {
    background:$white;
  }
  .el-menu {
    background: $transparent !important;
    .menu-wrapper .el-menu-item{
      //background: $transparent !important;
    }
    .el-submenu.is-active {
      &>div{
        background: #596097;
      }
    }
    .el-menu-item {
      background: #393f6c !important;
      position: relative;
    }
    //     .el-submenu__title{
    //       color:#888888
    //     }
    //     .el-menu-item .icon{
    //       color: #888888 !important;
    //     }
    .el-submenu__title:hover {
      background: #596097;
    }
    .el-menu-item:hover {
      background: #596097 !important;
    }
    .el-menu-item.is-active {
      color: #fff !important;
      background: #596097  !important;
    }
  }
  .el-menu--popup-right-start{
    margin: 0;
  }
  .el-menu--vertical {
    background: #eeeeee  !important;
    .el-menu-item.is-active {
      background: #596097  !important;
    }
    .el-menu-item.is-active:before{
      content:"";
      position: absolute;
      top:0;
      left: 0;
      background-color: $cheng;
      width: 3px;
      height: 100%;
    }
  }
  .bg {
    background: $white;
    border-radius: 5px;
    margin-bottom: 15px;
  }
  .home {
    color: $black;
  }
  .shortcut:hover {
    background: rgba(63, 133, 255, 0.2);
  }
  .shortcut span {
    color: rgba(0, 0, 0, 0.8);
  }
  .shortcut .icon {
    color: #3f85ff;
    margin-top: 6px;
  }
  .monitorIcon {
    color: $white;
  }
  .handleTitle-right .state .icon {
    color: $ccc;
  }
  .monitorDetail .mDBottom {
    color: $black50;
  }
  .stageDetail .stageList {
    background: $black10;
  }
  .stagePass {
    background: #79C6FF
  }
  .stageNot {
    background: $white10;
  }
  .chart {
    color: #000000;
  }
  .handleTitle-center .el-button {
    background: $transparent;
    border-color: $black20;
    color: $c666;
    width: 60px;
    height: 26px;
    line-height: 26px;
    padding: 0;
    font-size: 12px;
  }
  .handleTitle-center .el-button:focus, .handleTitle-center .el-button:hover {
    background: $black5;
  }
  .handleTitle-center .el-button.active {
    background: $black10;
    border-color: $black20;
  }
  .zi {
    background: #FFE1E0;
  }
  .lan {
    background: #C0ECFF;
  }
  //.el-submenu__title{
  //  color: #000000;
  //}
  .el-submenu .icon {
    //color: #888888 !important;
  }
  .el-menu-item {
    //color: #666666;
  }
  .blackBox {
    background: $white;
    border-radius: 5px;
    padding: 20px;
  }
  #main > div:not(.home) {
    padding: 20px;
    background: $white;
  }
  #main > div.outer-layer {
    background: $transparent;
    padding: 0;
    .el-tab-pane, .secondPart {
      background: #ffffff;
      box-shadow: 0px 1px 5px 0px rgba(203, 201, 201, 0.5);
      border-radius: 4px;
      padding: 10px;
    }
  }
  .el-tabs__nav{
    padding-left: 0;
  }
  .el-tabs .el-tabs__item.is-active {
    //font-size: 16px;
    color: #ffffff !important;
  }
  .customerBox .el-tabs .el-tabs__item.is-active {
    color:#333333!important;
  }
  .el-tabs .el-tabs__item.is-active:before {
    content:"";
    width: 90%;
    height: 100%;
    position: absolute;
    top:0;
    left:5%;
    right:5%;
    bottom:0;
    background: #ff6b07;
    border-radius: 5px;
    z-index:-1;
  }
  .customerBox .el-tabs .el-tabs__item.is-active:before {
    display: none;
  }
  .el-tabs--bottom .el-tabs__item.is-bottom:nth-child(2), .el-tabs--bottom .el-tabs__item.is-top:nth-child(2), .el-tabs--top .el-tabs__item.is-bottom:nth-child(2), .el-tabs--top .el-tabs__item.is-top:nth-child(2){
    padding-left: 17px;
  }
  .el-tabs--bottom .el-tabs__item.is-bottom:last-child, .el-tabs--bottom .el-tabs__item.is-top:last-child, .el-tabs--top .el-tabs__item.is-bottom:last-child, .el-tabs--top .el-tabs__item.is-top:last-child{
    padding-right: 17px;
  }
  //vxe-table
  .vxe-table {
    background: $transparent;
    border-left: 1px solid $transparent;
    border-right: 1px solid $transparent;
    &:after, &:before {
      background: $transparent;
      border: none;
    }
    .vxe-table--border-line:before, .vxe-table--border-line:after {
      border: none;
    }
    .vxe-header--column, .vxe-body--column, .vxe-footer--column {
      //background-image: none!important;
    }
    .vxe-table--footer {
      border-top: 1px solid $eee;
    }
    .vxe-checkbox > input + .vxe-checkbox--icon {
      border: 1px solid $LightBlue;
    }

    .vxe-checkbox > input:checked + .vxe-checkbox--icon:before {
      border-color: $LightBlue;
    }
    .vxe-checkbox.is--indeterminate > input:not(:checked) + .vxe-checkbox--icon:before {
      background: $LightBlue;
      left: 0;
    }
    .vxe-table--fixed-left-wrapper, .vxe-table--fixed-right-wrapper {
      background: $white;
      border: none;
    }
    .vxe-table--fixed-left-wrapper:before, .vxe-table--fixed-right-wrapper:before {
      border-top: 1px solid $transparent;
    }
    .vxe-table--header-wrapper .vxe-table--repair {
      border-bottom: $transparent;
    }
    .vxe-table--footer-wrapper, .vxe-table--header-wrapper {
      //border-top: 1px solid $transparent;
    }
    .vxe-table--header-wrapper {
      background: $transparent;
    }
    .vxe-body--row {
      background: $transparent;
    }
    &.size--medium .vxe-body--column, &.size--medium .vxe-footer--column, &.size--medium .vxe-header--column {
      border-right: none;
      border-bottom: 1px solid $transparent !important;
      //background-image: none;
    }
    //.vxe-cell{
    //  color:$black;
    //}
    th .vxe-cell {
      color: #aaaaaa;
    }

    //滚动条
    .vxe-table--footer-wrapper {
      background: $transparent;
    }
    &.show--foot .vxe-table--main-wrapper>div:nth-last-child(2),&.show--foot .vxe-table--fixed-left-wrapper>div:nth-last-child(2),&.show--foot .vxe-table--fixed-right-wrapper>div:nth-last-child(2) {
      overflow-x: hidden!important;
    }
    &.show--foot .vxe-table--footer-wrapper{
      margin-top: 0!important;
    }
    &.show--foot .vxe-table--fixed-left-wrapper,&.show--foot .vxe-table--fixed-right-wrapper{
      padding-bottom: 11px;
      padding-bottom: 18px\0;
      box-sizing: content-box;
    }
    @-moz-document url-prefix() {
      &.show--foot .vxe-table--fixed-left-wrapper,&.show--foot .vxe-table--fixed-right-wrapper{
        padding-bottom: 18px;
      }
    }
    //滚动条结束

    .vxe-checkbox > input + .vxe-checkbox--icon {
      background: $transparent;
    }
    .el-button {
      &:hover span {
        border-bottom: 1px solid rgba(35, 148, 232, 1);
        padding-bottom: 3px;
      }
      padding-right: 5px;
      padding-left: 5px;
      border: none;
      color: $btnBlue;
      background: transparent;
      .el-icon--right {
        margin-left: 0px;
        margin-right: 2px;
      }
    }
    .disabled_button {
      color: $black50;
      cursor: no-drop;
    }
    .vxe-header--column {
      .vxe-resizable.is--line:before {
        //display: none;
        background: $white;
        width: 2px;
      }
      //.vxe-resizable.is--line:hover:before {
      //  display: block;
      //}
    }
    .vxe-cell .vxe-input, .vxe-cell .vxe-default-select {
      height: 24px;
    }

  }
  #main .customerBox .el-tabs__item {
    background: #fff;
  }
  #main > div.customerBox {
    padding: 0;
    background: none;
  }
  .paginationContain {
    margin-top: 10px;
    border: 1px solid $transparent;
    .el-pagination {
      color: $white;
    }
    .el-select .el-input .el-input__inner {
      background: $transparent;
      border: 1px solid $bbb;
    }
    .el-pagination .btn-next, .el-pagination .btn-prev {
      border: 1px solid $bbb;
      color: $c999;
      background: $transparent;
    }
    .el-pagination button:disabled {
      border: 1px solid $eee;
      color: $eee;
    }
  }
  .el-pager li {
    border: 1px solid $bbb;
    color: $black;
  }
  .el-pager li.active + li {
    border: 1px solid $bbb;
  }
  .el-pager li.active {
    color: #000000;
    background: $eee;
  }
  div .el-button:not(.el-button--text) {
    background: $narrowBlue;
    color: $white;
    border: 1px solid transparent;
    padding-right: 11px;
    padding-left: 11px;
    font-size: 12px;
    &.is-disabled {
      color: $black50;
      cursor: not-allowed;
      background-image: none;
      background-color: $white;
      border-color: $black50;
    }
  }
  div .el-button:not(.el-button--text):hover {
    background: #0865ef;
  }
  div .el-button:not(.el-button--text):active {
    background: #0353ca;
  }
  div.ciic-table-operate .el-button:hover,div.ciic-table-operate .el-button:hover{
    background: none;
  }
  //.el-tabs .el-tab-pane .divButtonRigth .el-button:hover {
  //  background: #0865ef;
  //}
  div .el-input__inner {
    border: 1px solid $bbb;
    background: $transparent;
  }
  .el-message-box .el-button, .tanceng .el-button {
    width: 60px;
    height: 30px;
    border-radius: 4px;
    background-color: $white50;
    border: 1px solid $LightBlue2;
    color: $LightBlue2;
  }
  //.el-message-box .el-input__inner{
  //  border:1px solid $bbb;
  //  background: $transparent;
  //}
  .el-message-box .el-button.el-button--primary, .tanceng .el-button.el-button--primary {
    border: none;
    background-color: $LightBlue2;
    color: $white;
  }
  .el-tabs {
    .el-tabs__item {
      color: $black;
    }
    .is-active {
      //font-size: 18px;
      color: $cheng;
    }
    .el-tabs__item::after {
      background: $aaa;
    }
  }
  #main .customerBox.salary{
    background: $white;
  }
  .handleBtn {
    color: $c888;
  }

  .customerBox .customerInfo, .customerBox .customerDetail {
    background: $white;
  }
  .customerBox .el-tabs__nav-scroll {
    border: none;
    //background: #f8f8f8 url('../../assets/img/tabBg.png') repeat-x;
    background: #f8f8f8;
    background-position: left bottom;
  }
  .customerBox[data-v-9d390940] .el-tabs__item {
    background: #fff;
  }

  .editForm .el-form-item__label,
  .ruleForm .el-form-item__label{
    font-size: 13px;
    font-weight: 600;
    color: rgba(51, 51, 51, 1);
  }
  .editForm .formText {
    font-size: 13px;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
  }
  .customerBox .el-tabs__content {

    box-shadow: 0px 4px 5px 0px rgba(203, 201, 201, 0.5);
  }
  .customerBox .customerInfo {
    box-shadow: 0px 1px 5px 0px rgba(203, 201, 201, 0.5);
    border-radius: 4px;

  }
  .customeInfo-pageGroup {
    background: rgba(51, 133, 255, 1);
    color: rgba(255, 255, 255, 1);
  }
  //空数据
    .icon-kong{
      margin-top: 15px;
      font-size: 100px;
      color:#dbdbdb;
    }
    .noData{
      color:#dbdbdb;
    }
}

.customeInfo-pageGroup {
  width: 49px;
  height: 28px;

  border-radius: 100px 0px 0px 100px;
  font-size: 12px;
  font-weight: 400;

  text-align: center;
  line-height: 28px;
  top: 0;
  right: -30px;
  cursor: pointer;
  position: relative;
}

.zhezhao {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
}

.tanceng {
  position: fixed;
  z-index: 19;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: 20px;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 5px;
  .el-dialog__header, .tancengTitle {
    line-height: 40px;
    height: 40px;
    padding: 0 30px;
    background: $eee;
    color: #000000;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .icon {
      color: #BABABA;
      font-size: 14px;
    }
  }
  .tancengContent {
    padding: 0px 36px;
    margin: 30px 0;
    .shortSetTitle span {
      font-size: 14px;
      color: $black;
      line-height: 42px;
    }
    .shortSetTitle span + span {
      font-size: 12px;
      color: $c999;
    }
    p {
      font-size: 12px;
      color: #000000;
    }
    .list-group {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    li {
      width: 88px;
      height: 30px;
      position: relative;
    }
    .list-group-item {
      width: 88px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 12px;
      margin-bottom: 20px;
      color: #000000;
      border: 1px solid $ccc;
      cursor: pointer;
    }
    .list-group-item.active {
      border: 1px solid #FF7E00;
    }
  }
  .tancengBtn {
    margin-top: 30px;
    text-align: center;
    .el-button--small {
      width: 80px;
      height: 30px;
    }
  }
  div .el-button {
    border-color: #78AEE5;
    color: #78AEE5;
  }
  .el-button--primary {
    background: #78AEE5;
    color: $white;
  }
  .vxe-table {
    &:before, &:after {
      border: none;
    }
    .vxe-table--border-line:after, .vxe-table--border-line:before {
      border: none;
    }
    .vxe-cell {
      color: $black;
    }
  }
}

.el-dialog {
  .vxe-table {
    .vxe-cell {
      color: $black;
    }
  }
  .el-dialog__header {
    background-color: $eee;
    line-height: 30px;
    display: flex;
    .el-dialog__title {
      color: $black;
      font-size: 16px;
    }
    .el-dialog__headerbtn {
      width: 60px;
      height: 40px;
      top: 0px;
      right: 0;
    }
  }
  .el-dialog__footer {
    text-align: center;
  }
}

.el-pagination {
  .btn-prev {
    margin-right: 10px;
  }
  .el-pager li {
    background: $transparent;
    margin-right: 10px;
    border-radius: 3px;
    font-size: 12px;
    min-width: 30px;
    height: 24px;
    line-height: 24px;
  }
  button, span:not([class*=suffix]) {
    font-size: 12px;
    min-width: 30px;
    height: 24px;
    line-height: 24px;
    border-radius: 3px;
  }
  .el-pagination__sizes .el-input .el-input__inner {
    height: 24px;
    line-height: 24px;
    font-size: 12px;
  }
  .el-input--mini .el-input__icon {
    line-height: 24px;
  }
}

.el-tabs {
  .el-tabs__nav-wrap::after {
    height: 0;
  }
  .el-tabs__active-bar {
    height: 0;
  }
  .divButtonRigth {
    margin-bottom: 0px;
    position: absolute;
    right: 0;
    top: -44px;
    align-items: center;
    .el-input{
      margin-right: 10px;
      .el-input__inner{
        height: 34px;
      }
    }
  }
}

.outer-layer {
  border: none;
}

.invoiceWidth {
  width: 860px !important;
}

.invoiceCeng {
  width: 700px;
  p {
    margin: 0 !important;
  }
  .vxe-table {
    background: $white;
    .vxe-table--footer-wrapper {
      background-color: $white;
    }
  }
  .invoicesBox {
    display: flex;
    justify-content: space-between;

    .invoice {
      width: 140px;
      height: 190px;
      padding: 30px 20px 20px;
      box-sizing: border-box;
      border: 1px solid $ccc;
      border-radius: 4px;
      display: flex;
      align-items: center;
      flex-direction: column;
      cursor: pointer;
      &.active {
        transform: scale(1.05);
        box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.1);
        background: $white50;
      }
      &:hover {
        transform: scale(1.05);
        background: $white20;
      }
      .invoiceIcon {
        width: 68px;
        height: 68px;
      }
      .icon {
        font-size: 68px;
      }
      span {
        color: $black;
        font-size: 16px;
      }
      p {
        font-size: 12px;
        color: $black;
        text-align: center;
      }
    }
  }
  .invoicesTitle {
    display: flex;
    line-height: 24px;
    margin-top: 20px;
    font-size: 14px;
    color: #F2A500;
    .invoiceTitleIcon {
      width: 16px;
      height: 16px;
      margin-right: 10px;
      .icon {
        font-size: 16px;
        color: #F19E00;
      }
    }
  }
  .invoicesContent {
    p {
      font-size: 12px;
      color: $black;
      line-height: 24px;
      text-indent: -20px;
      padding-left: 24px;
    }
    margin-bottom: 0px;
  }
  .invoiceDetail {
    padding: 20px 36px;
    .vxe-button {
      line-height: 26px;
      border: none;
      padding: 0;
    }
    .vxe-button:not(.is--disabled) {
      color: $c999;
      font-size: 22px;
    }
    th .vxe-cell {
      font-size: 12px;
    }
    .invoiceTable {
      box-shadow: 0px 1px 7px 0px $black10;
      border-radius: 5px;
      background: $white;
      padding: 14px 20px;
      .vxe-table .vxe-body--column:not(.col--ellipsis),
      .vxe-table .vxe-footer--column:not(.col--ellipsis),
      .vxe-table .vxe-header--column:not(.col--ellipsis) {
        padding: 0;
      }
      .vxe-table--filter-wrapper .vxe-default-input,
      .vxe-table--filter-wrapper .vxe-default-select,
      .vxe-table--filter-wrapper .vxe-default-textarea,
      .vxe-table .vxe-default-input,
      .vxe-table .vxe-default-select,
      .vxe-table .vxe-default-textarea {
        height: 26px;
        padding: 0 6px;
        color: $black;
        font-size: 12px;
      }
    }
    .invoiceTypeTitle {
      display: flex;
      .invoiceTypeName {
        width: 96px;
        height: 36px;
        color: #ffffff;
        font-size: 12px;
        padding-left: 16px;
        margin-right: 10px;
        line-height: 34px;
        background: url("~@/assets/img/form/invoiceName.png");
      }
      .invoiceCompanyName {
        color: $black;
        font-size: 16px;
        margin-top: 1px;
        line-height: 36px;
      }
    }
    .invoiceLists {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      .invoiceList {
        display: flex;
        flex: 1;
        .invoiceCount {
          display: flex;
          flex-direction: column;
          align-items: center;
          flex: 1;
          position: relative;
          margin: 10px 0;
          &::before {
            content: "";
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -15px;
            height: 30px;
            width: 1px;
            background: $ccc;
          }
          &:last-child::before {
            display: none;
          }
          span {
            color: $black;
            font-size: 16px;
            line-height: 26px;
          }
          span + span {
            color: $c999;
            font-size: 12px;
            line-height: 22px;
          }
        }
      }
      .invoiceListBtn {
        margin-left: 10px;
        .el-button {
          width: 53px;
        }
      }
    }
  }
}

.backBtn {
  background: url('back.png') no-repeat 0 0;
  padding-left: 7px;
  width: 50px;
  height: 24px;
  text-align: center;
  color: $black;
  font-size: 12px;
  line-height: 24px;
  cursor: pointer;
}

//表格拖动改变宽度
.vxe-table .vxe-header--column .vxe-resizable.is--line:before {
  background: $white30;
  width: 2px;
}


//首页表头设置 样式无法穿透
.setting {
  .el-dialog__wrapper .el-dialog .el-dialog__body {
    height: 500px;
    overflow-y: auto;
  }
}

.defaultInvoice {
  .tancengBtn {
    margin-top: 30px;
    text-align: center;
    .el-button--small {
      width: 80px;
      height: 30px;
    }
  }
  .vxe-button {
    line-height: 26px;
    border: none;
    padding: 0;
  }
  .vxe-button:not(.is--disabled) {
    color: $c999;
    font-size: 22px;
  }
  th .vxe-cell {
    font-size: 12px;
  }
  .vxe-table {
    background: #ffffff;
    .vxe-table--footer-wrapper {
      background-color: #ffffff;
    }
  }

}

//客户设置中心（杨波）


.customerBox .customerInfo, .customerBox .customerDetail {
  border: none;
}

.customerBox .customerDetail {
  padding: 0;
  box-shadow: none;
}

.el-tabs__content {
  box-shadow: 0px 1px 5px 0px rgba(203, 201, 201, 0.5);
}

#main .customerBox .el-tabs__nav {
  border-radius: 4px 50px 0px 0px;
  height: 34px;
  line-height: 30px;
  padding-left: 0;
  top: 4px;
  left: 0px;
  background-position: -5px 0;
}

#main .customerBox .el-tabs__header {
  margin: 0;
}

#main .customerBox .el-tabs__item {
  box-shadow: 0px -1px 3px 0px rgba(203, 201, 201, 0.44);
  height: 30px;
  line-height: 30px;
  padding: 0 30px 0 26px !important;
  border-radius: 4px 50px 0px 0px;

  z-index: 10;
  position: relative;
}

#main .customerBox #tab-cusCont, #main .customerBox #tab-billSetting, #main .customerBox #tab-empBankCard {
  left: -20px;
  z-index: 9;
}

#main .customerBox #tab-cusEncl, #main .customerBox #tab-invoiceSetting, #main .customerBox #tab-empFamily {
  left: -40px;
  z-index: 8;
}

#main .customerBox #tab-cusTransfer, #main .customerBox #tab-payerSetting, #main .customerBox #tab-empDeclareTax {
  left: -60px;
  z-index: 7;
}

#main .customerBox #tab-cusCostCentre, #main .customerBox #tab-payrollList, #main .customerBox #tab-empMaterial {
  left: -80px;
  z-index: 6;
}

#main .customerBox .el-tabs__item.is-active {
  z-index: 50 !important;
  font-size: 13px;
  /* box-shadow:0px -2px 4px 0px rgba(203,201,201,0.44);
  border-radius:4px 40px 0px 0px; */
}
#main .customerBox .customerDetail .el-tabs__nav .el-tabs__item:nth-child(2){
  z-index:45;
  left:0px;
}
#main .customerBox .customerDetail .el-tabs__nav .el-tabs__item:nth-child(3){
  z-index:40;
  left:-20px;
}
#main .customerBox .customerDetail .el-tabs__nav .el-tabs__item:nth-child(4){
  z-index:35;
  left:-40px;
}
#main .customerBox .customerDetail .el-tabs__nav .el-tabs__item:nth-child(5){
  z-index:30;
  left:-60px;
}
#main .customerBox .customerDetail .el-tabs__nav .el-tabs__item:nth-child(6){
  z-index:25;
  left:-80px;
}
#main .customerBox .customerDetail .el-tabs__nav .el-tabs__item:nth-child(7){
  z-index:20;
  left:-100px;
}
#main .customerBox .customerDetail .el-tabs__nav .el-tabs__item:nth-child(8){
  z-index:15;
  left:-120px;
}
#main .customerBox .customerDetail .el-tabs__nav .el-tabs__item:nth-child(9){
  z-index:10;
  left:-140px;
}
#main .customerBox .customerDetail .el-tabs__nav .el-tabs__item:nth-child(10){
  z-index:5;
  left:-160px;
}
#main .customerBox .customerDetail .el-tabs__nav .el-tabs__item:nth-child(11){
  z-index:1;
  left:-180px;
}
#main .customerBox .el-tabs__item::after {
  display: none;
}

.editForm .formText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//.operator .el-icon-circle-close{
//  position: relative;
//  left: -10px;
//  top:-32px;
//}
//.operator .el-icon-arrow-up{
//  display: block!important;
//  width: 50px;
//  text-align: left;
//  padding-left: 5px;
//}
//.is-focus .is-reverse{
//  text-align: right;
//  padding-right: 5px;
//}
.operator .el-form-item__content{
  display: table;
  width: 200px;
}
.operator .qingkong{
  color: #aaa;
  font-size: 12px;
  position: absolute;
  right: 26px;
  top: 50%;
  margin-top: -15px;
  cursor: pointer;
  width: 20px;
  height: 30px;
  text-align: center;
  z-index: 6;
}