.el-dropdown-menu__item {
  font-size: 12px;
  line-height: 28px;
}

.el-menu--horizontal {
  border-bottom: none !important;
}

.el-menu {
  border-right: 0 !important;
  background-color: #1C2C52;
}

.el-menu--display,
.el-menu--display+.el-submenu__icon-arrow {
  display: none;
}
.el-menu--popup .el-menu--display,
.el-menu--popup .el-menu--display+.el-submenu__icon-arrow {
  display: block;
}
.el-table th{
  background-color: rgb(245, 245, 245);
  font-weight: bold;
}
@media only screen and (max-width: 1920px) {
  .el-table tbody{
    font-size:14px;
  }
  .hidden-1920 {
    display: none;
  }
}
@media only screen and (max-width: 1440px) {
  .el-table tbody{
    font-size:12px;
  }
  .hidden-1440 {
    display: none;
  }
}
@media only screen and (max-width: 1366px) {
  .el-table tbody{
    font-size:11px;
  }
  .hidden-1366 {
    display: none;
  }
}


.el-message__closeBtn:before{
  color: black;
}
.el-submenu__title:hover{
  background: #21355F;
}

.el-loading-spinner .el-loading-text{
  font-size:20px;
}

.el-table {
  .el-button--text:hover {
    text-decoration: underline;
  }
}