

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin scrollBar {
  ::-webkit-scrollbar-track-piece {
    background-color: #fff;
  }
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: #fff;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: hsla(220,4%,58%,.3);
  }
}
@mixin radius($width,$size,$color){
  width: $width;
  height: $width;
  line-height: $width;
  border-radius: $width;
  text-align: center;
  border-width: $size;
  border-style:solid;
  border-color: $color;

}
@mixin relative {
  position: relative;
  width: 100%;
  height: 100%;
}

@mixin pct($pct) {
  width: #{$pct};
  position: relative;
  margin: 0 auto;
}

@mixin triangle($width, $height, $color, $direction) {
  $width: $width/2;
  $color-border-style: $height solid $color;
  $transparent-border-style: $width solid transparent;
  height: 0;
  width: 0;
  @if $direction==up {
    border-bottom: $color-border-style;
    border-left: $transparent-border-style;
    border-right: $transparent-border-style;
  }
  @else if $direction==right {
    border-left: $color-border-style;
    border-top: $transparent-border-style;
    border-bottom: $transparent-border-style;
  }
  @else if $direction==down {
    border-top: $color-border-style;
    border-left: $transparent-border-style;
    border-right: $transparent-border-style;
  }
  @else if $direction==left {
    border-right: $color-border-style;
    border-top: $transparent-border-style;
    border-bottom: $transparent-border-style;
  }
}
