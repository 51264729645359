

.sidebar-container {
  background: #1C2C52;
  transition: width .6s;
  &.is-active {
    width: 64px !important;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){
  .sidebar-container {
    transition: none\9
  }
}
.el-menu-item.is-active {
  // color: $mainBg !important;
  color: #EF4922 !important;
  // background: #2C467E;
}
.el-menu-item{
  color: white;
}
.nest-menu .el-submenu>.el-submenu__title,
.el-submenu .el-menu-item {
  background-color: #2C467E !important;
  //&:hover {
  //  background: #00142a !important;
  //}
}

.el-submenu__title{
  color: white;
  display: flex;
  align-items: center;
  background: #393f6c !important;
}
.el-submenu__title.is-active{
  color: #EF4922 !important;
}
.sidebar-container .el-menu--collapse{
  width: 100%;
}
.out-menu .el-icon-arrow-down{
  color: #fff;
}
.out-menu .el-icon-arrow-down:before{
  content: "\e6d9";
}
.el-submenu>.el-submenu__title .el-submenu__icon-arrow{
  transform: rotateZ(0);
  color: #fff;
}
.el-submenu>.el-submenu__title .el-submenu__icon-arrow:before{
  content: "\E6D9"
}
.el-submenu.is-opened>.el-submenu__title .el-submenu__icon-arrow:before{
  content: "\e6d8";
}
.el-menu-item > .el-tooltip{
  width: 64px!important;
}