

@import './element-ui.scss';
@import './top.scss';
@import './mixin.scss';
@import './sidebar.scss';
/*禁止复制
{
	webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	outline: none;
}*/

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

@include scrollBar();
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

body {
  min-width: 1366px;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Microsoft YaHei";
  line-height: 2;
}

html,
body {
  height: 100%;
}

fieldset,
img {
  border: 0;
}

ol,
ul {
  list-style: none;
}

em {
  font-style: normal;
}

input,
button,
select,
textarea {
  outline: none;
}

textarea {
  resize: none;
}

//禁用了文本的拖拉，尤其在谷歌下
p {
  //text-align: justify;
  text-justify: distribute;
}

//为了使文本段落左右两边对齐
p {
  word-wrap: break-word
}

a {
  text-decoration: none;
}

i {
  display: inline-block;
}

/*a:hover { color: #f60; text-decoration:none; }*/

address,
cite,
code,
em,
th {
  font-weight: normal;
  font-style: normal;
}

a {
  color: inherit;
  text-decoration: none;
  display: inline-block;
}

/*a:link{color:#009;}*/

/*a:visited{color:#800080;}*/

a:hover,
a:active,
a:focus {
  text-decoration: underline;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.hidden {
  display: none;
}

.padding {
  padding: 0 !important;
}

.margin {
  margin: 0 !important;
}
.mb10 {
  margin-bottom: 10px;
}
.pull-right {
  float: right !important;
  ;
}

.pull-left {
  float: left !important;
}

.pull-center {
  text-align: center;
}

.pull-flex {
  display: flex;
}

.pull-overflow {
  overflow: hidden;
}

.pull-auto {
  height: auto;
  overflow: hidden;
}

.pull-height {
  height: 100%;
  overflow: hidden;
}

.pull-fixed {
  position: fixed;
  left: 0;
  top: 0;
}

.text-white {
  color: #fff;
}

.clearfix {
  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
} //main-container全局样式
.app-main {
  min-height: 100%
}

.filter-container {
  padding-bottom: 10px;
  .filter-item {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 10px;
  }
}

.app-container {
  padding: 0 10px;
  box-sizing: border-box;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  webkit-appearance: none !important;
  visibility: hidden;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.ciic-link{
  color: #1890ff;
  text-decoration:underline;
  cursor:pointer;
  outline: 0;
  background-color: transparent;
  transition: color .3s;
  webkit-text-decoration-skip: objects;
}
.ciic-link:hover {
  color: #40a9ff;
}

.ciic-cursor-pointer {
  cursor:pointer;
}

//过滤控件相关样式
.el-tabs__content{
  overflow: visible;
}
.ciic-filter-form .el-form-item{
  width: 100%;
  display: flex;
}

.ciic-filter-form .el-form-item__content{
  flex: 1 !important;
  margin-left: 0 !important;
  line-height: 0;
}
.ciic-filter-form .el-form-item__label{
  line-height: 32px;
}
.ciic-filter-form .el-range-editor--small.el-input__inner{
  width: 100%;
}

.ciic-filter-form .el-cascader--small{
  width: 50%;
}

.ciic-filter-btns{
  text-align: right;
  margin-top: 5px;
  .btn{
    padding: 10px 15px;
  }
}

.ciic-filter-form .el-date-editor.el-input{
  width: 95%;
}

.icon {
  width: 1em; height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.ciic-table-operate{
  display: flex;
}


@media only screen and (min-width: 1360px) {
  .table-money{
    padding-right:25px;
  }
}
