.light-theme {
  background: #f8f8f8;
  .el-menu--vertical {
    max-height: 100%;
    //overflow: auto;
  }
}
.el-dialog__wrapper{
  color:#000000;
}
.light-theme, .el-dialog__wrapper .el-dialog__body {
  //scroll bar
  ::-webkit-scrollbar-thumb {
    background: rgba(204, 204, 204, 0.5) !important;
  }
  ::-webkit-scrollbar-track-piece {
    background: rgba(204, 204, 204, 0.2) !important;
  }
  ::-webkit-scrollbar {
    background: transparent !important;
  }
  .vxe-table .el-button {
    font-size: 12px;
    font-weight: 400;
    color: rgba(35, 148, 232, 1);
  }
  .vxe-table .el-dropdown-selfdefine {
    color: rgba(35, 148, 232, 1);
    padding: 0 5px;
    outline: none;
    cursor: pointer;
  }

  .el-dropdown-menu__item span.table-dropdown-item {
    display: block;
    width: 70px;
    color: rgba(35, 148, 232, 1);
    text-align: center;
  }
  .el-dropdown-menu__item.is-disabled span.table-dropdown-item, .el-dropdown-menu__item.is-disabled {
    color: #bbb;
    cursor: not-allowed;
  }
  .vxe-cell .ciic-table-operate {
    justify-content: center;
  }
  .el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover {
    background-color: #ecf5ff;
    color: #66b1ff;
  }
  // 表头样式
  .vxe-header--row th {
    background: rgba(241, 241, 241, 1);
  }
  .vxe-header--row span {
    color:#333;
  }
  .vxe-table.border--default .vxe-body--column{
    background-image: linear-gradient(#e8eaec,#e8eaec);
  }
  .vxe-table .vxe-header--row .vxe-cell {
    height: 24px;
    padding-top: 0;
  }
  .vxe-table.size--medium .vxe-header--column.col--ellipsis {
    height: 34px;
  }
  .vxe-table th .vxe-cell {
    font-size: 14px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
  }
  //表间样式
  .vxe-table.vxe-editable.size--medium .vxe-body--column {
    height: 47px;
  }
  
  .vxe-table .vxe-cell {
    font-size: 12px;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
  }

  .vxe-table .vxe-body--row.row--current {
    background: rgba(230, 247, 255, 1);
  }
  .vxe-table.size--medium .vxe-body--column, .vxe-table.size--medium .vxe-footer--column, .vxe-table.size--medium .vxe-header--column {
    border-bottom: none !important;
  }

  //分页
  .el-pagination .el-pagination__total {
    font-size: 10px;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
  }
  .el-dropdown .icon {
    color: #2394e8;
  }
  div .el-button:not(.el-button--text){
    background: #3385FF;
    color: #fff;
    border: 1px solid transparent;
    padding-right:11px;
    padding-left:11px;
    font-size: 12px;
  }
  .vxe-table .el-button{
    background: none;
  }
}

.dark-theme {
  .el-dropdown-menu {
    background: #142142;
    border: none;
  }
  .vxe-table .el-dropdown-selfdefine {
    color: #142142;
    padding: 0 5px;
    outline: none;
    cursor: pointer;
  }
  .el-dropdown-menu__item {
    color: #ffffff;
  }
  .el-dropdown-menu__item:hover {
    background: rgba(105, 133, 231, 0.56);
    color: #ffffff;
  }
  .el-dropdown-menu.el-popper .popper__arrow {
    border-bottom-color: #142142;
  }
  .el-dropdown-menu.el-popper .popper__arrow:after {
    border-bottom-color: #142142;
  }
  .el-dropdown-menu__item--divided {
    border-top: 1px solid rgba(255, 255, 255, 0.3);
  }
  .el-dropdown-menu__item--divided:before {
    background: none;
  }
  .el-dropdown-menu__item span.table-dropdown-item {
    display: block;
    width: 70px;
    color: #ffffff;
    text-align: center;
  }
  .el-dropdown-menu__item.is-disabled span.table-dropdown-item, .el-dropdown-menu__item.is-disabled {
    color: #bbb;
    cursor: not-allowed;
  }
  .vxe-cell .ciic-table-operate {
    justify-content: center;
  }
  //表间样式
  .vxe-table.vxe-editable.size--medium .vxe-body--column {
    height: 47px;
  }
  .vxe-table .vxe-cell {
    font-size: 12px;
    font-weight: 400;
    color: #E2E3E3;
  }
  .vxe-header--row th {
    background: rgba(255, 255, 255, 0.2);
  }
  .vxe-header--row span {
    color: #ffffff;
    font-size: 14px;
  }
  .vxe-table .vxe-header--row .vxe-cell {
    height: 24px;
    padding-top: 0;
  }
  .vxe-table.size--medium .vxe-header--column.col--ellipsis {
    height: 34px;
  }
  .vxe-table th .vxe-cell {
    font-size: 14px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
  }
  .vxe-table .vxe-body--row.row--current {
    background: rgba(105, 125, 195, 0.56);
  }
  .vxe-table.size--medium .vxe-body--column, .vxe-table.size--medium .vxe-footer--column, .vxe-table.size--medium .vxe-header--column {
    border-bottom: none !important;
  }
  .vxe-table.border--default .vxe-body--column {
    background-image: linear-gradient(rgba(105, 125, 195, 0.4), rgba(105, 125, 195, 0.4));
  }
  .el-dropdown .icon {
    color: #E2E3E3;
  }
}

.vxe-table .el-dropdown {
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.vxe-table .el-button + .el-button {
  margin-left: 5px;
}

.vxe-table .aLink .vxe-cell {
  color: #66b1ff;
  cursor: pointer;
}

//按钮
.tabButtonCon .el-button--primary.el-button--small {
  padding: 3px 14px;
  height: 24px;
  font-size: 13px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  line-height: 18px;
}

.tabButtonCon {
  text-align: right;
  padding: 10px 0;
}

.noData {
  font-size: 18px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  line-height: 30px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.warningBtn {
  background-color: darkorange !important;
}
  //表间样式
  .light-theme .vxe-table.vxe-editable.size--medium .vxe-body--column.reduce-height {
    height: 38px !important;
  }