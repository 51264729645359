





































#app {
  width: 100%;
  height: 100%;
  overflow:hidden;
}
.el-pagination {
  text-align: right;
  margin-top: 10px;
}
/*.v-modal{*/
/*  z-index: 10 !important;*/
/*}*/
