//字体图标
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
// 表格
.vxe-table .vxe-header--column{
    line-height: 20px;
}
.paginationContain{
    border: 1px solid #e8eaec;
    text-align: right;
    border-top: none;
}
.vxe-table .vxe-cell{
    color: #000;
    height: 34px;
    padding-top: 4px;
    overflow: hidden;
    //text-overflow: ellipsis;
    //white-space: nowrap;
}
.vxe-table .vxe-cell span{
  //display: inline-block;
  //overflow: hidden;
  //height: 34px;
  //width: 100%;
}
.vxe-table .vxe-cell .el-button{
  margin-bottom: 0px!important;
}
.vxe-table--header-wrapper{
    background: #e9e9e9;
}
.el-pagination{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 0;
    padding: 10px 5px;
}
.ciic-table-operate{
  padding: 0px 0px;
  height: 30px;
}
.ciic-table-operate .el-button--text{
  padding: 0;
}
.hetong{
  margin: 5px auto 0 !important;
  background: #404040;
}
.hetong .el-dialog__body{
  padding: 0;
}
.hetong .el-dialog__body>div{
  height: calc(100vh - 50px);
}
.hetongfujian .upload-demo{
  display: flex;
  align-items: center;
  height: 30px;
}

.hetongfujian .el-upload__tip {
  display: inline-block;
  margin-left: 10px;
  margin-top: 0;
  margin-right: 10px;
  text-decoration: underline;
  cursor: pointer;
}
.hetongfujian .el-upload-list__item{
  margin-top: 0;
}
.hetongfujian .el-upload-list__item-name,.hetongfujian .el-upload-list__item-status-label{
  line-height: 30px;
}
.hetongfujian .el-upload-list__item .el-icon-close{
  top:8px;
}
// 弹窗
.el-dialog__header{
    background: #ddd;
}
.el-dialog__title{
    color: #333;
}
.el-dialog__headerbtn{
    top: 25px;
}
.formDialog{
  border: 1px solid #ddd;
  border-left: none;
}
.formDialog  .el-input,.ciicForm.formDialog .el-date-editor.el-input{
  width:260px;
}

// 表单
.ciicForm .el-form-item__content{
    vertical-align: middle;
    width: 350px;
    box-sizing: border-box;
}
.ciicForm .el-date-editor.el-input,.ciicForm .el-date-editor.el-input__inner{
    width: 100%;
    box-sizing: border-box;
}
.el-input.el-input--suffix{
    vertical-align: middle;
}
//.el-input__inner{
//    height: 28px;
//    line-height: 28px;
//}
.el-select{
    width: 100%;
}
.el-cascader{
  width: 100%;
  //line-height: 30px;
  //height: 30px;
}
.center{
    text-align: center;
}
.ciicForm{
    padding-top: 20px;
}
.el-dialog__body .ciicForm{
    padding-top: 0;
}
.ciicFormContain{
    max-width: 1366px;
    margin: 0 auto;
}
.marginTop20{
    margin-top: 20px;
}
.marginBottom20{
    margin-bottom: 20px;
}
.font18{
  font-size: 18px;
  color: #000;
}
.marginTop30{
    margin-top: 30px;
}
.marginBottom30{
    margin-bottom: 30px;
}


.titleDot .el-form-item .el-form-item__label::before{
    content: '*';
    color: #F56C6C;
    margin-right: 4px;
 }



//  动态表单样式（目前有问题）

 .ciicForm .el-form-item{
    margin-bottom: 0;
}
 .ciicForm .el-form-item__label{
    line-height: 27px;
    vertical-align: top;
    background: #f8f8f8;
    color: #666;
    padding-right: 26px;
     padding-bottom: 11px;
     padding-top: 12px;
    border-left: 1px solid #ddd;
}
.ciicForm .el-input,.ciicForm .el-textarea__inner,.ciicForm .el-checkbox-group{
    margin-left: 20px;
    margin-top: 10px;
}
.ciicForm>.el-row>.el-row{
    border: 1px solid #ddd;
    border-left: none;
  padding-top: 7px;
}
.ciicForm .placeholder .el-form-item__label{
    color: transparent;
}
.ciicForm .row3 .el-form-item__label{
    height: 138px;
}
.ciicForm .el-textarea__inner{
    height: 104px;
}
.ciicForm .upload-demo{
    display: flex;
    margin-left: 20px;
    margin-top: 15px;
    .el-upload-list__item:first-child{
      margin-left: 20px;
      margin-top: 5px;
      width: 290px;
    }
  }
  .ciicForm .el-form-item__error{
    top: 18px;
    left: 380px;
    white-space: nowrap;
  }

.vxe-table.size--medium .vxe-body--column, .vxe-table.size--medium .vxe-footer--column, .vxe-table.size--medium .vxe-header--column{
  border-right:1px solid #fff;
}
.vxe-table.size--medium .vxe-header--column{
  border-bottom: 1px solid #fff;
}
//.vxe-table.size--medium .vxe-body--column:nth-last-child(1), .vxe-table.size--medium .vxe-footer--column:nth-last-child(1), .vxe-table.size--medium .vxe-header--column:nth-last-child(2){
//  border-right:none;
//}
.vxe-table.size--medium .vxe-header--column:nth-last-child(1){
  border-bottom: none;
}
.vxe-table.vxe-editable.size--medium .vxe-body--column{
  height: 40px;
}
.vxe-table .vxe-table--fixed-left-wrapper{
  border-right: 1px solid #fff;
}
.ciicForm .el-form-item__error{
  top: 37px;
  left: 22px;
}
.el-dialog__header{
  padding: 5px 20px 5px;
}
.el-dialog__headerbtn {
  top: 14px;
}
.ciicForm .upload-demo .el-upload-list__item{
  margin-left: 20px;
  width: 290px;
}
.el-form-item__content, .el-input__icon{
  //line-height: 14px;
}
//.el-form-item__content .el-radio-group{
//  margin-left: 20px;
//}
.vxe-table.size--medium .vxe-body--column, .vxe-table.size--medium .vxe-footer--column, .vxe-table.size--medium .vxe-header--column{
  padding: 4px 0;
}
.vxe-table .vxe-header--column {
  line-height: 26px;
}
.el-tabs__item::after{
  content: "";
  display: block;
  position: absolute;
  width: 1px;
  height: 20px;
  background: #ccc;
  left: 0;
  top: 10px;
}
.el-tabs--top .el-tabs__item.is-top:nth-child(2)::after{
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0px;
  background: #ccc;
  top: 10px;
}
.insertKong{
  width: 168px;
  height: 52px;
  background: none;
  border-left: none;
  .el-input{
    display: none;
  }
}
.ciicForm .row3 .el-form-item__content{
  width: 78%;
}
//.el-button--small, .el-button--small.is-round {
//  padding: 7px 11px;
//}

.el-switch{
  margin-top:10px
}

//特殊表单查看和编辑公用一个页面，查看的页面修改disabled的样式
.bsp-disable-form {
  .el-input.is-disabled{
    .el-input__inner{
      background-color: #fff;
      color: #000000;
      cursor: default;
      border: 0 none;
      padding-top: 10px
    }
    .el-input__suffix{
      display: none;
    }
    .el-input__prefix{
      top: 7px;
    }
  }
  .el-range-editor.is-disabled{
    background-color: #fff;
    color: #000000;
    cursor: default;
    border: 0 none;
  }
  .el-range-editor.is-disabled input{
    background-color: #fff;
    color: #000000;
    cursor: default;
    padding-top: 10px
  }
  .is-disabled{
    .el-range__icon{
      margin-bottom: 0px;
    }
    .el-range-separator{
      margin-bottom: 0px;
    }
  }

}
.outer-layer{
  padding:5px 20px 20px;
  border:1px solid #eee;
  border-radius: 5px;
}
//动态表单样式
.customer-info{
  margin:0 10px 10px;
}
.customer-info .customer-info{
  margin: 0;
}
.customer-info-item {
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  background:rgba(241,241,241,1);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  color: #333;
  font-size: 16px;
  cursor: pointer;
}
.customer-info-title span{
  margin-left: 10px;
  font-size: 14px;
}
.customer-info-title {
  display: flex;
  align-items: center;
}

.customer-info-title .icon {
  margin-right: 10px;
  font-size: 24px;
}

.customer-info-button .icon {
  margin: 0 10px;
  font-size: 16px;
  cursor: pointer;
}

.editForm {
  margin-top: 10px;
  padding: 10px;
  color: #000000;
  font-size: 14px;
  .el-form{
    display: flex;
    flex-wrap: wrap;
  }
  .onlyRead{
    .el-form-item{
      margin-bottom: 10px;
    }
  }
}

.editForm .insertKong,.insertKong {
  background: none;
  border: none;
  display: none;
}

.editForm .el-form-item {
  font-size: 12px;
  line-height: 30px;
  margin-bottom: 20px;
}

.editForm .el-form-item >>> .el-form-item__label {
  color: #999999;
  font-size: 12px;
  line-height: 30px;
}

.editForm .el-form-item >>> .el-form-item__content .el-radio-group {
  margin: 0;
}

.editForm .el-form-item >>> .el-form-item__content .el-radio-group .el-radio {
  line-height: 30px;
}
.editForm .small .el-form-item__label{
  line-height: 30px;
}

/*.editForm .el-form-item >>> .el-form-item__content span {*/
/*display: inline-block;*/
/*line-height: 30px;*/
/*}*/

.formText {
  line-height: 30px;
}
.divButtonRigth{
  display: flex;
  justify-content: flex-end;
}
.forbideButton{
  color: #999999;
  cursor: auto;
}
@media screen and (max-width: 1439px) {
  .editForm .el-col-8 {
    width: 50%;
  }
}
.flexBetween{
  justify-content: space-between;
}
//蓝边按钮
.emptyBtnStyle{
  border-color:#66b1ff;
  color:#66b1ff;
}


//.vxe-table--filter-wrapper{
//  z-index: 3000!important;
//}

//.el-popper{
//  z-index: 3001!important;
//}

//客户公司详情
.customerBox {
  position: relative;

  .fixed-top-bg {
    position: fixed;
    box-sizing: content-box;
    padding-right: 70px;
    height: 40px;
    top: 65px;
    z-index: 9;
      background: #f8f8f8;
  }
  .customerInfo {
    border: 1px solid #dddddd;
    border-radius: 5px;
    padding: 20px 30px 20px 40px;
    margin-bottom: 20px;
  }
  .fixed-top {
    position: fixed;
    padding: 10px 30px 10px 40px;
    background-color: #f4f4f4;
    top: 80px;
    z-index: 20;
  }
  .fixed-top2 .el-tabs--top {
    position: fixed;
    top: 132px;
    margin-left: 21px;
    z-index: 20;
    background-color: #fff;
    transition: all 0.6s;
  }
  .fixed-top2  .el-tabs__header {
    margin-bottom: 0;
  }
  .el-tabs__nav-scroll{
    border-bottom:1px solid #ccc;
  }
  .customerInfo-head {
    transition: all 0.6s;
    display: flex;
    justify-content: space-between;
  }
  .customerfixed,.customerInfo{
    transition: all 0.6s;
  }
  .customerInfo-name {
    display: flex;
    align-items: center;
  }
  .customerInfo-name span {
    font-size: 24px;
    line-height: 30px;
    color: #000000;
    margin-right: 20px;
  }

  .customerInfo-name .icon {
    font-size: 28px;
  }
  .customerInfo-name.stateOk .icon {
    color: #FF9000;
  }
  .customerInfo-name.stateNotfinish .icon {
    color: #cccccc;
  }
  .customeInfo-pageGroup  .el-button {
    background: #fff;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 12px;
    color: #666;
  }
  .customeInfo-pageGroup  .el-button:hover{
    background: #bfbfbf;
  }
  .customerInfo-body {
    font-size: 12px;
  }
  .customerInfo-body-detail {
    padding: 20px 0;
    display: flex;
    justify-content: start;
    color: #999999;
  }
  .customerInfo-body-detail span + span {
    color: #010000;
  }
  .customerInfo-btns  .el-button {
    border: 1px solid #4FA1ED;
    color:#ffffff;
    padding: 5px 10px;
    line-height: 18px;
  }
  .customerInfo-btns  .el-button.is-disabled{
    color: #cacaca;
    border: 1px solid #cacaca;
  }
  .customerInfo-btn {
    color: #008AFF;
  }
  .customerInfo-btns .icon {
    font-size: 18px;
    margin-right: 4px;
    vertical-align: top;
  }
  .interval {
    margin: 0 35px;
  }
  .customerDetail {
    border: 1px solid #dddddd;
    border-radius: 5px;
    padding: 20px;
  }
}
.el-form-item__label{
  line-height: 30px;
}
//表格里的按钮数量
.countBtn{
  position: relative;
}
.countBtnN{
  position: absolute;
  height: 18px;
  display: inline-block;
  text-align: center;
  line-height: 18px;
  background: red;
  top: -11px;
  right: -9px;
  border-radius: 9px;
  padding: 0 6px;
  min-width: 6px;
  color:#ffffff;
}
.vxe-table ::-webkit-scrollbar{
  height: 10px!important;
  width: 10px!important;
}

//使用余额
.totalRow{
  width: 100%;
  height: 100px;
  border:1px solid #E2F4FF;
  display: flex;
  margin-bottom: 15px;
}
.balanceMonth{
  width: 100px;
  padding: 15px;
  background: #E2F4FF;
  color: #3F85FF;
  box-sizing: border-box;
}
.bal-month{
  font-size: 48px;
  line-height: 52px;
  color: #3F85FF;
  text-align: center;
}
.bal-year{
  font-size: 16px;
  line-height: 22px;
  text-align: center;
}
.balanceTotals{
  flex: 1;
  padding:0 70px 0 20px;
}
.bal-company{
  font-size: 14px;
  line-height: 42px;
  color: #910000;
}
.bal-datas{
  display: flex;
  justify-content: space-between;
  color:#999;
  font-size: 12px;
  line-height: 24px;
}
.bal-data{
  text-align: center;
}
.bal-data-count{
  font-size: 14px;
  color:#FF6801;
  line-height: 26px;
}
.balanceExpand{
  padding: 0;
}
.expandBox{
  display: flex;
  background-color: #F4F4F4;
  padding:10px 30px;
}
.expandLeft{
  width: 180px;
  list-style: none;
}
.expandRight{
  flex: 1;
}
.expandBox li{
  margin-bottom: 22px;
}
.expandBox li span,.expandBox .el-form-item__content span{
  font-size: 12px;
  color:#000000;
  line-height: 30px;
}
.expandBox li span+span{
  color:#666666;
}
.expandBox li .money{
  color:#FF6801;
}
.expandBox  p{
  font-size: 12px;
}
.expandBox .el-form-item__content span.money{
  color:#FF6801;
}
.expandRight .el-form-item .el-form-item__content,.useBalanceMoney,.inline,.expandRightIcon{
  display: flex;
  justify-content: space-between;
}
.expandRight .el-form-item__content p{
  flex: 1;
}
.expandRight .el-form-item__content .expandRightIcon{
  width: 240px;
}
.deductIcon{
  cursor: pointer;
  width: 20px;
  .icon{
    margin-top: 5px;
    font-size: 20px;
    color:#FFAF62;
  }
}
.financialMoney{
  display: flex;
  justify-content: space-between;
}
.expandRight .el-input{
  width: 180px;
}
.expandBox .publicBalance p{
  padding-left: 20px;
  color:#666666;
  font-size: 12px;
  line-height: 30px;
}
.expandRightIcon .el-input__inner{
  border: none!important;
  background: #ffffff!important;
}
.vxe-body--expanded-cell{
  padding: 0!important;
}
.publicBalanceTotal{
  line-height: 30px;
}
.w120,.bal-table .vxe-table--footer colgroup col:first-child{
  width: 60px!important;
  padding: 0;
}
.w120>div{
  padding-right: 0!important;
}
.publicBalanceTotal .money{
    color:#FF6801;
    font-size: 12px;
  }
.money .vxe-cell{
  color:#FF6801!important;
  font-size: 16px;
  padding-left: 0;
}
.expandBox .mb22{
  margin-bottom: 22px;
}
.publicBalance{
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
}
.publicBalance label,.publicBalanceTotal label{
  font-size: 12px;
}
.bal-table .vxe-cell{
  height: 20px;
  line-height: 20px;
  padding-top: 0px;
}
#PaySlip .el-dialog__body{
  padding: 0;
}
.vxe-table .vxe-default-input{
  height: 24px!important;
}


#dialog1 .el-dialog__body{
  padding: 10px;
}
//chooseBox
.chooseBox{
  display: flex;
  .chooseLeft{
    width: 300px;
    padding:0 20px;
    .chooseCollapse{
      height:350px;
    }
    .el-scrollbar {
      height: 100%;
    }
    .el-scrollbar__wrap {
      overflow: scroll;
      width: 110%;
      height: calc(100% + 7px);
    }
    .el-collapse-item__header{
      background: #eee;
      padding-left: 20px;
      border-bottom:1px solid #fefefe;
    }
    .el-collapse-item__content{
      padding-bottom: 0;
    }
    .chooseLi{
      padding-left: 20px;
      line-height: 30px;
      border-bottom:1px solid #eeeeee;
    }
    .el-collapse-item__arrow{
      margin-right:50px;
    }
  }
  .chooseRight{
    flex: 1;
    padding:0 20px;
  }
  .chooseTitle{
    display: inline-flex;
    align-items: center;
    p{
      line-height: 30px;
      font-size: 14px;
      margin-right: 10px;
    }
    .chooseIcon{
      font-size: 14px;
    }
  }
  .chooseDatas{
    border:1px solid #eee;
    border-radius: 4px;
    padding:15px;
    height: 258px;
    overflow-y: auto;
    .el-tag{
      margin: 5px;
    }
  }
}
//来款拆分
.incomingSplitTitle{
  display: flex;
  background-color: #eee;
  line-height: 40px;
  font-size: 14px;
  text-align: center;
  border-top:1px solid #e1e1e1;
}
.incomingSplitData{
  display: flex;
  line-height: 60px;
  font-size: 14px;
  color:#333333;
  text-align: center;
  background-color: #fff;
}
.incomingSplitData li .el-form-item{
  margin-bottom: 0;
}
.incomingSplitData li .el-form-item.is-error{
  margin-top: -14px;
}
.incomingSplitTitle li:first-child,.incomingSplitData li:first-child{
  width: 100px;
  border:1px solid #e1e1e1;
  border-top:none;
}
.incomingSplitTitle li:nth-child(2),.incomingSplitData li:nth-child(2){
  width: 250px;
  overflow:hidden; //超出的文本隐藏
  text-overflow:ellipsis; //溢出用省略号显示
  white-space:nowrap; //溢出不换行
}
.incomingSplitTitle li:nth-child(3),.incomingSplitData li:nth-child(3){
  width: 150px;
  overflow: hidden; //超出的文本隐藏
  text-overflow: ellipsis; //溢出用省略号显示
  white-space: nowrap; //溢出不换行
}
.incomingSplitTitle li:nth-child(4),.incomingSplitData li:nth-child(4){
  flex:1;
  display: flex;
  align-items: center;
}
.incomingSplitData li,.incomingSplitTitle li{
  border-right:1px solid #e1e1e1;
  border-bottom:1px solid #e1e1e1;
  padding:0 10px;
}
.incomingSplitData li .el-input{
  width: 100px;
}
.incomingSplitData li span{
  margin-left: 20px;
  font-size: 12px;
}
.incomingSplitData li .el-select .el-input{
  width: 100%;
}
//.el-form-item__content{
//  line-height: 30px;
//}
.editForm .el-form-item__content{
  //height: 30px;
  //line-height: 30px;
}
.span-box .icon{
  margin-left: 4px;
  color:#FF9000;
}
 .icon.error{
  color:#ff2300;
}
//工资单设置
.sequenceFrom .el-form-item__content{
  display: flex;
  align-items: center;
  color:#FF9000;
  //height: 30px;
}
.sequenceFrom .el-form-item__content .icon{
  margin-left: 5px;
}
//日期选择框宽度
.el-date-editor.el-input, .el-date-editor.el-input__inner{
  width: 100%;
}

.el-link.el-link--primary:hover:after{
  display: none;
}
//拖拽的效果
.ghostClass{
  background:rgba(35, 148, 232, 0.1);
  padding: 10px;
  margin: 0!important;
}
.fallbackClass{
  border:2px dotted rgba(35, 148, 232, 1)!important;
  opacity: 1!important;
  padding-top:20px;
  box-sizing: content-box!important;
  background:rgba(255, 255, 255, 0.9);
}
 .customer-info-title span{
  font-size:14px;
  font-weight:600;
}
//数据导入
.batchBox{
  position: relative;
  .stepContent{
    padding-top: 40px;
    margin-bottom: 15px;
    padding-bottom:1px;
  }
  padding: 20px;
  .uploadContent{
    height: 140px;
  }
  .batchSteps{
    position: relative;
    margin: 0 auto;
    padding: 0 70px;
    display: flex;
    justify-content: space-between;
    background: #f4f4f4;
    height: 140px;
  }
  .batchSteps:before{
    content:"";
    position: absolute;
    width: 80%;
    height: 2px;
    background: #c3cce1;
    top:50%;
    margin-top: -25px;
    left:10%;
  }
  .batchStep{
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:10px;
    flex: 1;
  }
  .stepIndex{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    margin: 10px 0;
    border-radius: 50%;
    border:1px solid transparent;
    box-sizing: border-box;
    font-size: 13px;
    color:#fff;
    background: #c3cce1;
  }
  .active .stepIndex{
    color: #ffffff;
    background: #409EFF;
    border: 1px solid #409eff;
  }
  .stepSplit{
    font-size: 40px;
    color:#dddddd;
    display: flex;
    align-items: center;
    display: none;
  }
  .batchStep p{
    font-size: 18px;
    line-height: 20px;
    margin-top: 3px;
    color:#999999;
  }
  .batchStep p+p{
    color:#999999;
    margin-top: 5px;
    font-size: 12px;
  }
  .active.batchStep p{
    color:#409EFF;
  }
  .active.batchStep p+p{
    color:#409EFF;
  }
  .el-upload-dragger{
    background: #f9f9f9;
    border:1px dashed #B5B5B5;
  }
  .uploadTip{
    padding: 20px;
    color: #666666;
    font-size: 12px;
  }
  .uploadTip-title{
    .icon{
      color:#FF9C00;
    }
    color:#FFA312;
    font-size: 14px;
  }
  .uploadTip-list{
    line-height: 30px;
    color:#999999;
  }

}
.matchList{
  border-top:1px solid #e4e4e4;
}
.showAll{
  position: relative;
  line-height: 30px;
  .el-checkbox__label{
    color:#000000;
  }
  .showzz{
    position: absolute;
    width: 110px;
    height: 30px;
    top:0;
    left:0;
    background: none;
    z-index: 99;
  }
}
.matchTable{
  .el-table td, .el-table th{
    padding:8px 0px;
  }
  .el-table td{
    color:#000000;
   }
  .el-table--enable-row-hover .el-table__body tr:hover>td{
    background: #FFF8C0;
  }
}

// .el-loading-mask{

//   background:rgba(255, 255, 255, 0.9) url('../assets/img/loading.svg') no-repeat;
//   background-size: 20%;
//   background-position: center center;
// }
// .el-loading-mask .el-loading-spinner{
//   display: none;
// }
//发票展开行
.invoiceTable{
  .expandBox{
    background: rgba(255,255,255,0.1);
    color:#ffffff;
    padding: 0;
    .vxe-header--column{
      padding: 0!important;
    }
    .vxe-header--row th{
      background: none!important;
    }
    .vxe-body--column{
      height: 30px!important;
      padding:0!important;
      background: none!important;
    }
    .vxe-footer--column{
      padding:0!important;
      background: none;
    }
    .vxe-table .vxe-table--footer-wrapper{
      border-top: none;
    }
    .vxe-table .vxe-table--footer{
      background: #f2fbff;
    }
  }
}
.dark-theme{
  .vxe-table{
    .vxe-table--expanded .vxe-table--expand-btn {
      color: #ffffff !important;
    }
    .vxe-table .vxe-table--footer{
      background: #464e68!important;
    }
  }
  .el-dialog{
    .vxe-table{
      .vxe-table--expanded .vxe-table--expand-btn {
        color: #606266 !important;
      }
    }
  }
  .labelWhite{
    .el-form-item__label{
      color:#ffffff;
    }
  }
}


.el-message__icon{
  font-size: 16px;
}
.next-btn{
  margin-bottom: 20px;
}
.popIcon{
  color:#ffa204;
}
.loopBox{
  &.el-popover{
    width:296px;
    background:#FFFBE5;
    padding:10px 20px;
    border-radius:4px;
    border:1px solid #E8DDA3;
    .popper__arrow{
      border-bottom-color: #E8DDA3;
      &:after{
        border-bottom-color:#FFFBE5;
      }
    }
  }
  .loopError{
    margin-top: 10px;
    &:first-child{
      margin-top: 0;
    }
    .loopType{
      font-size: 13px;
      color:#333;
      line-height: 23px;
      font-weight: 600;
      .icon{
        margin-right: 5px;
      }
    }
    .loopTypeInfo .icon{
      color:#ffa204;
    }
    .loopTypeError .icon{
      color:#ff0301;
    }
    .loopTypeWarn .icon{
      color:#ef6e70;
    }
    .loopContent p{
      white-space: normal;
      color:#666;
      font-size: 12px;
      margin-bottom: 5px;
      text-indent: -12px;
      padding-left: 32px;
    }
  }
}

.shortCount{
  position: absolute;
  top: 6px;
  left: 50%;
  margin-left: 5px;
  background-color: red;
  border-radius: 10px;
  color: #FFF!important;
  display: inline-block;
  font-size: 12px;
  height: 18px;
  line-height: 18px!important;
  padding: 0 6px;
  text-align: center;
  white-space: nowrap;
}

//首页快捷方式
.shortcut{
  position: relative;
}

.shortSetTitle {
  padding-left: 10px;

}
.shortSetTitle span{
  font-size:12px;
  font-weight:500;
  color:rgba(102,102,102,1);
}


.selectedItem {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.selectedItemLine{
  justify-content: flex-start;
}
.selectedItem li {
  width: 88px;
  margin: 8px 10px 14px;
}

.selectedItem li.list-group-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1); */
  border-radius: 2px;
}

.selectedItem.listGroup li.list-group-item>span:nth-of-type(1){
  box-shadow: none;
}
.selectedItem li.list-group-item>span:nth-of-type(1){
  display: inline-block;
  width: 50px;
  height: 30px;
  background:rgba(255,255,255,1);
  box-shadow:0px 0px 4px 0px rgba(105,154,202,0.2);
  border-radius:4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectedItem li.list-group-item>span .icon {
  color: rgba(95,148,199,1);
  font-size: 20px;
}
.selectedItem.listGroup li.list-group-item>span .icon {
  color: rgba(155,156,155,1);
}
.selectedItem.listGroup li .text{
  color: #7e7e7e;
}

.selectedItem li .text {
  color: #333;
  font-size: 12px;
  line-height:17px;
  margin-top: 4px;
}
.el-dialog__body{
  padding:20px;
}
//退票
.errorTipTop{
  background: #FFFBE5;
  border:1px solid #E8DDA3;
  border-radius:4px;
  line-height: 20px;
  padding:5px 10px;
  span{
    font-size: 12px;
    color:#FF0000;
  }
  i{
    margin-right: 10px;
    color:#FF0000;
  }
}
.errorRead{
  color:#333333;
  .el-form-item{
    margin-bottom: 0;
    p.formText{
      color:#666666;
      font-size: 12px;
    }
  }
}
//右侧贴边标签
.rightSideTitle{
  font-size: 13px;
  line-height: 29px;
  color:#ffffff;
  background: #3385FF;
  padding:0px 10px 0px 6px;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
  display: inline-block;
  margin-left: -20px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.rightSideTitle+.editForm{
  margin-top: 0;
}
//.show--foot .vxe-table--body-wrapper::-webkit-scrollbar,.show--foot .vxe-table--body-wrapper::-webkit-scrollbar-thumb{
//  display: none;
//}
.invoiceTable option:disabled{
  background: #efefef;
}
.el-input-number{
  width: 100%;
  line-height: 30px;
}


//穿梭框按钮样式
.transfer-center-item button{
  border-radius: 4px!important;
}
.transfer-center-item button:disabled{
  background-color: #a0cfff!important;
  border-color: #a0cfff!important;
}

.vxe-input--panel.type--date, .vxe-input--panel.type--month, .vxe-input--panel.type--week, .vxe-input--panel.type--year{
  z-index:9999!important;
}
.vxe-select--panel.is--transfer{
  z-index:9999!important;
}
//去掉number的箭头
.vxe-input--number-suffix{
  //display: none;
}
.el-form-item__label{
  display: inline-flex;
  justify-content: flex-end;
}
.matchSelect .el-select-group__wrap{
  display: block!important;
}
.vxe-table.is--empty,.vxe-table.is--empty .vxe-table--main-wrapper{
  min-height: 199px!important;
}
.vxe-table--empty-placeholder,.vxe-table.is--empty .vxe-table--body-wrapper{
  min-height: 155px!important;
}
//显示错误提示
.inlineBlock{
  display: inline-block;
}

.dialogHeight{
  height: 400px;
}

.requiredItem span{
  color:#F56C6C;
}

.infoForm .el-form-item__label{
  color:#999999;
}
.infoForm .el-radio__label{
  color:#999999;
}
.el-upload-dragger .el-upload__text{
  color:#999999;
}

.dark-theme .flexBetween .el-checkbox__label{
  color:#ffffff;
}
.salaryChecks{
  max-height: 300px;
  overflow-y: auto;
}
.salaryChecks  .el-checkbox {
  display: flex;
  align-items: center;
  margin-right: 0;
}

.salaryChecks  .el-checkbox__label {
  line-height: 26px;
  color: #000000;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 120px;
}

.salaryChecks  .el-checkbox__input {
  margin-top: 2px;
}

//导入第一步
.importType{
  padding: 40px;
  height: calc(100vh - 341px);
}
.importType-row{
  height: 100%;
}
.importType-box{
  padding-top: 50px;
  padding-bottom: 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.09);
}
.importType-title{
  font-size: 18px;
  line-height: 50px;
  height: 50px;
  color:#000;
}
.importType-explain{
  width: 100%;
  font-size: 14px;
  line-height: 30px;
  height: 30px;
  color:#666;
  text-align: center;
}
.importType-icon{
  font-size: 128px;
}
@media(max-width:1366px){
  .importType-icon{
    font-size: 98px;
  }
  .importType-icon+button{
    margin-top: -20px;
  }
  .importType-box{
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .importType-explain{
    height: 20px;
    line-height: 20px;
  }
}
.mult .importType-icon{
  color:#9571e9;
}
.single .importType-icon{
  color:#e75700;
  //line-height: 1.6;
}
.importType-btn{
  width: 170px;
  height: 50px;
  color:#fff;
  font-size: 14px;
  border-radius: 25px;
}
.mult .importType-btn{
  filter:alpha(opacity=100 finishopacity=50 style=1 startx=0,starty=0,finishx=170,finishy=38) progid:DXImageTransform.Microsoft.gradient(startcolorstr=#c280ff,endcolorstr=#9571e9,gradientType=0)!important;
  -ms-filter:alpha(opacity=100 finishopacity=50 style=1 startx=0,starty=0,finishx=170,finishy=38) progid:DXImageTransform.Microsoft.gradient(startcolorstr=#c280ff,endcolorstr=#9571e9,gradientType=0)!important;/*IE8*/
  background:#c280ff!important; /* 一些不支持背景渐变的浏览器 */
  background:-moz-linear-gradient(left top, #c280ff, #9571e9)!important;
  background:-webkit-gradient(linear, 0 0, 0 top, from(#c280ff), to(#9571e9))!important;
  background:-o-linear-gradient(left top, #c280ff, #9571e9)!important;
}
.single .importType-btn{
  filter:alpha(opacity=100 finishopacity=50 style=1 startx=0,starty=0,finishx=170,finishy=38) progid:DXImageTransform.Microsoft.gradient(startcolorstr=#e6875b,endcolorstr=#e75700,gradientType=0)!important;
  -ms-filter:alpha(opacity=100 finishopacity=50 style=1 startx=0,starty=0,finishx=170,finishy=38) progid:DXImageTransform.Microsoft.gradient(startcolorstr=#e6875b,endcolorstr=#e75700,gradientType=0)!important;/*IE8*/
  background:#e6875b!important; /* 一些不支持背景渐变的浏览器 */
  background:-moz-linear-gradient(left top, #e6875b, #e75700)!important;
  background:-webkit-gradient(linear, 0 0, 0 top, from(#e6875b), to(#e75700))!important;
  background:-o-linear-gradient(left top, #e6875b, #e75700)!important;
}



//首页
.home{
  margin-top: -15px;
  padding-bottom: 15px;
  .home-title{
    color:#333;
    font-size:18px;
    line-height: 50px;
    height: 50px;
  }
  .calendar{
    position: relative;
  }
  .calendar>.calendar-tools{
    background: #959CD5;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .calendar>.calendar-tools .calendar-prev,.calendar>.calendar-tools .calendar-next{
    display: none;
  }
  .calendarSelect{
    position: absolute;
    z-index: 1;
    left: 200px;
    top:60px;
    display: flex;
  }
  @media (max-width: 1366px) {
    .calendarSelect{
      left: 11px;
      top:95px;
    }
    .calendar-tools{
      height: 80px!important;
    }
    .calendar td{
      height: 42px!important;
    }
  }
  .calendarSelect .el-select .el-input__inner{
    font-size: 12px;
    color:#575E99;
  }
  .calendarSelect p{
    width: 72px;
    font-size: 12px;
    color:#ffffff;
    line-height: 24px;
    text-align: right;
  }
  .calendarSelect-city{
    width: 100px;
    background: #ffffff;
    border-radius: 20px;
    color:#575E99;
  }
  .calendarSelect-type{
    width: 150px;
    background: #ffffff;
    border-radius: 20px;
    color:#575E99;
    flex: 1;
  }
  .calendarSelect .el-input__inner{
    border:none!important;
    height:24px;
    line-height: 24px;
  }
  //.el-select .el-input__inner{
  //  height: 24px;
  //  line-height: 24px;
  //}
  .calendarSelect-tips{
    height: 24px;
    display: flex;
    align-items: center;
    font-size: 12px;
    color:#333333;
  }
  .calendarSelect-tips i{
    display: inline-block;
    width: 17px;
    height: 17px;
    border-radius: 9px;
    background:#959CD5;
    margin-right: 10px;
  }
}
//iframe
.pubIndex .index .main .router{
  height: 100%!important;
}

//错误消息
.iconMessage{
  display: none;
}
.errMessage{
  //height: 50%;
  width: 30%;
  p{
    line-height: 24px;
    font-size: 12px;
    color:red;
    height:auto;
    overflow: auto;
    width: calc(100% - 40px);
  }
}
.uploadTip .el-form-item__content{
   display: flex;
 }
.uploadTip img{
  border:1px solid #bbbbbb;
  margin-right: 10px;
}
.uploadTip .el-icon-plus{
  border: 1px solid #bbbbbb;
  margin-right: 10px;
}
//富文本
.tox-tinymce-aux{
  z-index: 3000!important;
}
// 解决ie关闭dialog时闪一下的问题
.el-dialog__wrapper{
  &.dialog-fade-enter-active{
    -ms-animation:none;
  }
  &.dialog-fade-leave-active{
    -ms-animation:none;
  }
}
.el-menu .el-menu-item{
  //transition: none\0;
}

//vxe-table  筛选时间段框
.filter--active .el-date-editor .el-range-separator{
  line-height: 32px!important;
  height: 32px!important;
  margin-bottom: 0!important;
}
.filter--active .el-date-editor .el-range__icon{
  line-height: 32px!important;
  height: 32px!important;
  margin-bottom: 0!important;
}
//筛选按钮隐藏，除IE
.vxe-table .vxe-table--filter-footer{
  display: none;
  display: block\9;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){
  .vxe-table .vxe-table--filter-footer{
    display: block;
  }
}
.vxe-table--tooltip-wrapper{
  z-index:2500!important;
}

.el-date-editor .el-range__close-icon{
  display: flex;
  align-items: center;
  margin-right: -10px;
}
.caculateFormula {
  .ruleFormInline {
    display: flex;
  }
  .wageItemCard {
    max-height: 300px;
    overflow-y: auto;
    .wageItemTag {
      display: block;
      color: #FF6B07;
      text-align: center;
      cursor: pointer
    }
  }
}