//窄边框版样式修改
.index .left{
  //margin-top: 10px;
}
#main>div.el-tabs{
  background: transparent!important;
}
.el-tabs__nav{
  display: inline-flex;
  padding-left: 10px;
}
.el-tabs__item{
  //padding:0 17px;
  height: 34px;
  line-height: 34px;
  font-size: 14px;
  color:#666666!important;
}
.el-tabs .el-tabs__item::after{
  top:7px;
  background-color: #979797!important;
}
.el-tabs .el-tab-pane .divButtonRigth .el-button{
  min-width: 80px;
  padding:10px 14px;
  text-align-last: justify;
}
.el-tabs .divButtonRigth{
  top:-48px;
  right: 20px;
  //margin-bottom: 10px;
}
.divButtonRigth{
  margin-bottom: 10px;
}
.secondPart{
  background: #ffffff;
  box-shadow:0px 1px 5px 0px rgba(203,201,201,0.5);
  border-radius:4px;
  padding: 10px;
}
.vxe-table.border--default .vxe-header--column{
  background-image: none;
}

#main .newcustomerBox .el-tabs__nav{
  width: 105px!important;
}
#main .newcustomerBox .el-tabs__header{
  background: rgba(246,246,246,0.1);
}
#main .newcustomerBox .el-tabs__nav{
  top: 0;
}
#main .newcustomerBox .el-tabs__item{
  border-radius: 0;
  height: 44px;
  line-height: 44px;
  text-align: center;
  width: 105px!important;
  box-shadow:none;
  -webkit-box-shadow:none;
}
.dark-theme .newcustomerBox .el-tabs__item{
  background: transparent;
}
.dark-theme .newcustomerBox .el-tabs .el-tabs__item.is-active{
  color: rgba(226,227,227,1)!important;
  background: #262f51;
}
.dark-theme .newcustomerBox .customer-info-item,.light-theme .newcustomerBox .customer-info-item{
  background: transparent;
  position: relative;
  margin-bottom: 10px;
}
.dark-theme .newcustomerBox .customer-info-title{

}
.dark-theme .newcustomerBox .customer-info-item:after{
  position: absolute;
  content: "";
  height: 1px;
  border-top: 1px dotted #fff;
  top: 20px;
  left: 140px;
  width: calc(100% - 250px);
}

#main .newcustomerBox .el-tabs--left .el-tabs__nav-wrap.is-left::after{
  height: 0;
}
#main .newcustomerBox  .el-tabs--left .el-tabs__active-bar.is-left {
  right: 103px;
  background: #FF7979;
  top: 0;
  z-index: 99;
}

#main .newcustomerBox .el-tabs--left .el-tabs__nav-wrap.is-left{
  background: rgba(246,246,246,0.1);
}
#main .newcustomerBox .el-tabs__content{
  box-shadow:none;
  -webkit-box-shadow:none;
  overflow-y: auto;
  height: 100%;
}
//配置中心
.test{
  box-shadow: -2px 0px 6px -5px rgba(0,0,0,0.8);
  .el-tabs__content{
    overflow: hidden;
  }
  .el-tabs__nav{
    display: block;
  }
  .el-tabs__nav-wrap.is-left{
    margin-right: 0!important;
  }
}

#main .customerBox .test .el-tabs__nav{
  height: 100%;
}
#main .customerBox .test #tab-cusCont, #main .customerBox .test #tab-billSetting, #main .customerBox .test #tab-empBankCard {
  position: static;
}

#main .customerBox .test #tab-cusEncl, #main .customerBox .test #tab-invoiceSetting, #main .customerBox .test #tab-empFamily {
  position: static;
}

#main .customerBox .test #tab-cusTransfer, #main .customerBox .test #tab-payerSetting, #main .customerBox .test #tab-empDeclareTax {
  position: static;
}

#main .customerBox .test #tab-cusCostCentre, #main .customerBox .test #tab-payrollList, #main .customerBox .test #tab-empMaterial {
  position: static;
}
.light-theme .newcustomerBox .el-tabs__nav-scroll{
  background: #F6F6F6;
}
.light-theme #main .newcustomerBox .el-tabs__item{
  background: #F6F6F6;
}
.light-theme .newcustomerBox .el-tabs .el-tabs__item.is-active{
  background: #fff!important;
  color: #333!important;
}
.light-theme .newcustomerBox .customer-info-item:after{
  position: absolute;
  content: "";
  height: 1px;
  border-top: 1px dotted #979797;
  top: 20px;
  left: 140px;
  width: calc(100% - 250px);
}
.customer-info-title{
  position: relative;
}
.newcustomerBox .customer-info-title:before{
  content: "";
  position: absolute;
  width:7px;
  height:14px;
  background:rgba(104,131,238,1);
  top: 13px;
  left: -10px;
}
.newcustomerBox .el-dialog .customer-info-item{
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: #DDF4FF;
  color: #008AFF;
  font-size: 16px;
  &:after{
    display: none;
  }
  .customer-info-title:before{
    display: none;
  }
}